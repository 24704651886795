// when a company is non-profile company it's redirect you to profile page
import { useFinancialCompany } from '../../../context/companies/Companies';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_CONSTANTS from '../../../Routes/constants/route-constants';
import { COMPANY_TYPE } from '../Add/enums/company-type';
import { COMPANY_TYPE_ROUTE } from '../Add/enums/company-type-route';

export const useNonProfilePolicy = () => {
    const companyDetail = useFinancialCompany();
    const navigator = useNavigate();
    
    const companyTypeRoute = {
        [COMPANY_TYPE.PUBLIC]: COMPANY_TYPE_ROUTE.PUBLIC,
        [COMPANY_TYPE.PRIVATE]: COMPANY_TYPE_ROUTE.PRIVATE,
        [COMPANY_TYPE.NON_PROFILE]: COMPANY_TYPE_ROUTE.NON_PROFILE,
    };

    useEffect(() => {
        if (companyDetail?.type === COMPANY_TYPE.NON_PROFILE) {
            navigator(
                ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.PROFILE.ROOT.BY_COMPANY_ID(companyTypeRoute[companyDetail.type as COMPANY_TYPE], companyDetail.id).ABSOLUTE
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyDetail]);
};
