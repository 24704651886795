import React from 'react';
import styles from './RelatedItemCard.module.scss';
import { financialValues } from 'src/utils/financial-values';
import { financialInformationLegitFractions } from 'src/pages/Company/Show/CompanyFinancialInformation/constants';
import { IFinancialInformationRow } from 'src/pages/Company/Show/CompanyFinancialInformation/types';
import useFormulaTextBox from '../FormulaTextBox/hooks/useFormulaTextBox';
import { useFinancial } from '../../../../../../../../../../../../../context/companies/Companies';

const RelatedItemCard: React.FC<IFinancialInformationRow> = (props) => {
    const { currency } = useFinancial((global) => ({
        currency: global.financialSelectedCurrency,
    }));
    const { handleSelectItem } = useFormulaTextBox();

    return (
        <div className={styles.childCard} onClick={() => handleSelectItem(props)}>
            <div className={styles.rightSide}>
                <span className={styles.value}>
                    {financialValues(props.value, {
                        fixed: financialInformationLegitFractions,
                        ...(props.must_show_currency && { prefix: currency?.short_name }),
                    })}
                </span>
                <span className={styles.name}>{props.name ? props.name[0].name : ''}</span>
            </div>
        </div>
    );
};
export default RelatedItemCard;
