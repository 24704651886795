import { COMPANY_FINANCIAL_INFORMATION_STATE } from './enums';
import CompanyFinancialInformationViewMode from './Details';
import AddUpdateFinancialInformationWrapper from './AddUpdate';
import { financialActions, useFinancial, useFinancialCompanyId } from '../../../../context/companies/Companies';
import { companyService } from '../../../../api/services/company';
import { getAxiosError } from '../../../../utils/get-axios-error';
import { toast } from '../../../../utils/toast';
import { TOAST_STATUS } from '../../../../constants/toast-status';
import { useEffect } from 'react';
import { useNonProfilePolicy } from '../useNonProfilePolicy';
import { useParams } from 'react-router-dom';
import { COMPANY_TYPE_ROUTE } from '../../Add/enums/company-type-route';
import { IParams } from 'src/types/params';

const CompanyFinancialInformation: React.FC = () => {
    useNonProfilePolicy();
    const { companyType } = useParams<IParams>();
    const companyId = useFinancialCompanyId();
    const financialInformationInitiationState = useFinancial((global) => global.financialInformationState);
    useEffect(() => {
        companyService(companyType as COMPANY_TYPE_ROUTE)
            .getFinancialTypes(companyId)
            .then()
            .catch((err) => {
                const error = getAxiosError(err);
                const message = error?.message || 'Server Error';
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: message,
                });
            });
        return () => {
            financialActions.setFinancialInformationState(COMPANY_FINANCIAL_INFORMATION_STATE.VIEW_MODE);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!financialInformationInitiationState) return <span>Component Not Set!</span>;

    const componentByMode = {
        [COMPANY_FINANCIAL_INFORMATION_STATE.VIEW_MODE]: CompanyFinancialInformationViewMode,
        [COMPANY_FINANCIAL_INFORMATION_STATE.EDIT_MODE]: AddUpdateFinancialInformationWrapper,
    };

    const RenderComponent = componentByMode[financialInformationInitiationState];

    return <RenderComponent />;
};

export default CompanyFinancialInformation;
