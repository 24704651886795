import { useEffect, useState } from 'react';
import styles from './SelectFinancialTemplate.module.scss';
import Button from 'src/components/Kit/Button';
import Item from './Item';
import { companyService } from 'src/api/services/company';
import { ITemplatesResponse } from 'src/api/types/company';
import InitLoading from 'src/components/App/Loading/InitLoading';
import { MUTATION_FINANCIAL_STEP } from '../../../enums';
import { ITemplateState } from '../../../types';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { financialActions, useFinancial, useFinancialCompanyId, useFinancialTemplateId } from '../../../../../../../context/companies/Companies';
import { useParams } from 'react-router-dom';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { IParams } from 'src/types/params';

const SelectFinancialTemplate: React.FC = () => {
    const { companyType } = useParams<IParams>();
    const companyId = useFinancialCompanyId();
    const selectedTemplateId = useFinancialTemplateId();
    const { canChangeBasics } = useFinancial((global) => ({
        canChangeBasics: global.canChangeBasic,
    }));
    const [templates, setTemplates] = useState<ITemplateState[]>([]);
    const [getDataLoading, getDatasetLoading] = useState<boolean>(true);
    const [saveDataLoading, setSaveDataLoading] = useState<boolean>(false);

    const handleGetTemplates = async () => {
        getDatasetLoading(true);

        try {
            let res = await companyService(companyType as COMPANY_TYPE_ROUTE).getTemplates();
            setTemplates(
                res.data.data.map((tempResponse: ITemplatesResponse) => ({
                    id: tempResponse.id,
                    name: tempResponse.name,
                    types: tempResponse.financial_information_types,
                    description: tempResponse.description,
                }))
            );

            getDatasetLoading(false);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            getDatasetLoading(false);
        }
    };

    const handleGoNext = async () => {
        if (!selectedTemplateId) return;
        if (!canChangeBasics) {
            // It happens when admin has financial data and can not change template any more.
            financialActions.setMutationStep(MUTATION_FINANCIAL_STEP.SELECT_CURRENCY);
            return;
        }

        try {
            setSaveDataLoading(true);
            await companyService(companyType as COMPANY_TYPE_ROUTE).sendSelectedFinancialTemplate(companyId, selectedTemplateId);
            await companyService(companyType as COMPANY_TYPE_ROUTE).getFinancialTypes(companyId);

            setSaveDataLoading(false);
            financialActions.setMutationStep(MUTATION_FINANCIAL_STEP.SELECT_CURRENCY);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setSaveDataLoading(false);
        }
    };

    useEffect(() => {
        handleGetTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (getDataLoading) return <InitLoading />;

    return (
        <div className={styles['financial-information__select-template']}>
            <h3>Select Template Type</h3>
            <p>Choose template type you need from the following items:</p>
            <div data-cy={'templates-wrapper'}>
                {templates.map((template: ITemplateState) => (
                    <Item key={template.id} template={template} />
                ))}
            </div>
            <div className={styles.buttonsWrapper}>
                <Button
                    data-cy={'next-financial-form'}
                    disabled={selectedTemplateId === undefined || saveDataLoading}
                    loading={saveDataLoading}
                    position="right"
                    onClick={handleGoNext}
                    size="l"
                    color="primary"
                    customStyle={{ flex: 1 }}
                >
                    Next
                </Button>
            </div>
        </div>
    );
};

export default SelectFinancialTemplate;
