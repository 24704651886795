import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from 'src/api';
import { IAxiosResponse } from '../types/axios-response';
import { WidgetPayload } from 'src/api/types/widget-service-type';

export const widgetService = {
    getAllWidgets() {
        return AxiosInstance.get<IAxiosResponse<any>>(API_ROUTES.WIDGET.GET_ALL);
    },
    updateWidgets(widgets: WidgetPayload) {
        return AxiosInstance.put<IAxiosResponse<any>>(API_ROUTES.WIDGET.UPDATE, widgets);
    }
};
