import { classNames } from 'primereact/utils';
import styles from '../../CompanyProfile.module.scss';
import GoogleMap from 'src/components/App/LocationView';
import { useFinancialCompany } from '../../../../../../context/companies/Companies';

const ContactInformation = () => {
    const companyDetails = useFinancialCompany();

    return (
        <div className={classNames(styles.segmentWrapper, 'grid')}>
            <div className={classNames(styles.row, styles.topLeftBorderRadius, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Phone</h4>
                    <h4 className={styles.detail}>{companyDetails?.phone ? companyDetails?.phone : '_'}</h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderLeft, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Email</h4>
                    <h4 className={styles.detail}>{companyDetails?.email ? companyDetails?.email : '_'}</h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderLeft, styles.topRightBorderRadius, 'col-4')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Website</h4>
                    <h4 className={styles.detail}>
                        {companyDetails?.website ? (
                            <a href={companyDetails?.website} target="_blank" rel="noreferrer">
                                {companyDetails?.website}
                            </a>
                        ) : (
                            '_'
                        )}
                    </h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderTop, 'col-6')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Twitter</h4>
                    <h4 className={styles.detail}>
                        {companyDetails?.twitter_link ? (
                            <a href={companyDetails?.twitter_link} target="_blank" rel="noreferrer">
                                {companyDetails?.twitter_link}
                            </a>
                        ) : (
                            '_'
                        )}
                    </h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderLeft, styles.noBorderTop, 'col-6')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Linkedin</h4>
                    <h4 className={styles.detail}>
                        {companyDetails?.linkedin_link ? (
                            <a href={companyDetails?.linkedin_link} target="_blank" rel="noreferrer">
                                {companyDetails?.linkedin_link}
                            </a>
                        ) : (
                            '_'
                        )}
                    </h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderTop, 'col-6')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Instagram</h4>
                    <h4 className={styles.detail}>
                        {companyDetails?.instagram_link ? (
                            <a href={companyDetails?.instagram_link} target="_blank" rel="noreferrer">
                                {companyDetails?.instagram_link}
                            </a>
                        ) : (
                            '_'
                        )}
                    </h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderLeft, styles.noBorderTop, 'col-6')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Youtube</h4>
                    <h4 className={styles.detail}>
                        {companyDetails?.youtube_link ? (
                            <a href={companyDetails?.youtube_link} target="_blank" rel="noreferrer">
                                {companyDetails?.youtube_link}
                            </a>
                        ) : (
                            '_'
                        )}
                    </h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderTop, 'col-12')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Address</h4>
                    <h4 className={styles.detail}>{companyDetails?.address ? companyDetails?.address : '_'}</h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.noBorderTop, 'col-12')}>
                <div className={styles.segmentRtl}>
                    <h4 className={styles.title}>Arabic Address</h4>
                    <h4 className={styles.detail}>{companyDetails?.arabic_address ? companyDetails?.arabic_address : '_'}</h4>
                </div>
            </div>
            <div className={classNames(styles.row, styles.bottomBorderRadius, styles.noBorderTop, 'col-12')}>
                <div className={styles.segment}>
                    <h4 className={styles.title}>Location</h4>
                    {companyDetails?.latitude ? (
                        <div className={styles.locationWrapper}>
                            <GoogleMap
                                height="181px"
                                width="464px"
                                center={{
                                    lat: companyDetails?.latitude ? Number(companyDetails?.latitude) : null,
                                    lng: companyDetails?.longitude ? Number(companyDetails?.longitude) : null,
                                }}
                            />
                        </div>
                    ) : (
                        '_'
                    )}
                </div>
            </div>
        </div>
    );
};

export default ContactInformation;
