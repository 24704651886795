import React, { memo, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

interface ItemType<T> {
    id: string;
    content: React.ReactNode;
    data: T;
}

interface DraggableListProps<T> {
    initialItems: ItemType<T>[];
    onOrderChanged?: (newOrder: ItemType<T>[]) => void;
}

const DraggableList = <T,>(props: DraggableListProps<T>): JSX.Element => {
    const { initialItems, onOrderChanged } = props;
    const [items, setItems] = useState<ItemType<T>[]>(initialItems);

    const handleOnDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const newItems = Array.from(items);
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);

        setItems(newItems);

        if (onOrderChanged) {
            onOrderChanged(newItems);
        }
    };

    useEffect(() => {
        setItems(initialItems);
    }, [initialItems]);

    return (
        <div className="container">
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="droppable-list">
                    {(provided) => (
                        <ul
                            className="draggable-list"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {items.map(({ id, content }, index) => (
                                <Draggable key={id} draggableId={id} index={index}>
                                    {(provided, snapshot) => (
                                        <li
                                            className={`draggable-item ${snapshot.isDragging ? 'dragging' : ''}`}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            {content}
                                        </li>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

// Memoize the component and preserve the generic type
const MemoizedDraggableList = memo(DraggableList) as <T>(props: DraggableListProps<T>) => JSX.Element;

export default MemoizedDraggableList;
