import { useCallback, useLayoutEffect } from 'react';
import { financialActions, useFinancial } from '../../../../../../context/companies/Companies';
import { MUTATION_FINANCIAL_STEP } from '../../enums';

// handle permission for create/update financial information
const useMutationInitializer = () => {
    const setInitialMutationStep = useCallback(() => {
        const companyDetails = useFinancial.getState().company;
        const canChangeBasics = !!companyDetails?.can_change_template_or_currency_or_year_end_date;
        const templateId = companyDetails?.company_financial_information_template_id;
        const currencyId = companyDetails?.company_financial_information_currency_id;
        const yearEnd = companyDetails?.company_financial_information_year_end_date;

        financialActions.setCanChangeBasics(canChangeBasics);
        if (!canChangeBasics) financialActions.setMutationStep(MUTATION_FINANCIAL_STEP.SELECT_FINANCIAL_DATA); //Hint: For when admin does not refresh the page and just put some data and save it.

        //Hint: get this dates to push admin into next steps if added before.
        if (templateId && !currencyId) {
            //Hint: means that user selected template but not currency
            financialActions.setFinancialTemplateId(templateId);
            financialActions.setMutationStep(MUTATION_FINANCIAL_STEP.SELECT_CURRENCY);
        } else if (templateId && currencyId && yearEnd) {
            //Hint: means that user selected template and currency
            const financialYearEnd = yearEnd.split('-')[1];
            financialActions.setFinancialTemplateId(templateId);
            financialActions.setFinancialYearEnd(String(+financialYearEnd));
            financialActions.setFinancialSelectedCurrency(companyDetails?.selectedCurrency);
            financialActions.setMutationStep(MUTATION_FINANCIAL_STEP.SELECT_FINANCIAL_DATA);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        setInitialMutationStep();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export { useMutationInitializer };
