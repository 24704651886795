import React from 'react';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { IMenuData } from './types';
import MenuItem from './SidebarMenuItem';
import menuData from './menu-data';
import style from './SidebarMenu.module.scss';
import { MENU_VIEW } from 'src/enums/menu';
import { MenuProps } from 'src/types/menu';
import { classNames } from 'primereact/utils';
import { useMenu } from 'src/context/menu';

const SidebarMenu: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const { menuView } = useMenu();

    const recursivelyRenderMenuItem = (childrenData: IMenuData) => {
        const { children, permissions, ...menuItemProps } = childrenData;
        const hasValidPermission = permissions?.length ? permissions.some((permission) => checkPermissionAccess(permission)) : true;
        if (!hasValidPermission) return <></>;

        const componentProps = {
            key: menuItemProps.id,
            ...menuItemProps,
            ...(children && {
                children: children.map((innerChildrenData) => recursivelyRenderMenuItem(innerChildrenData)),
            }),
        } as MenuProps;

        return <MenuItem {...componentProps} />;
    };

    return (
        <div className={classNames(style.sidebarMenu, menuView === MENU_VIEW.MINIMIZE ? style.minimized : '')}>
            <ul>{menuData.map((menuItems: IMenuData) => recursivelyRenderMenuItem(menuItems))}</ul>
        </div>
    );
};

export default SidebarMenu;
