import React, { useEffect, useState } from 'react';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { useForm } from 'react-hook-form';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { assignPersonValidationSchema } from './validation-schema';
import Button from 'src/components/Kit/Button';
import { useNavigate, useParams } from 'react-router-dom';
import InitLoading from 'src/components/App/Loading/InitLoading';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { IAssignPersonFormData } from './types';
import { companyService } from 'src/api/services/company';
import { IAssignPersonToCompanyRequest } from 'src/api/types/company';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { assignPersonSchema, AssignPersonSchemaFields } from './schema';
import { personService } from 'src/api/services/person-service';
import { positionService } from 'src/api/services/position-service';
import { IFormGeneratorGeneralSchemaType } from 'src/types/form-generator-schema-type';
import { PERSON_TYPE } from '../enums/person-type';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { useFinancialCompany } from '../../../../../context/companies/Companies';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { IParams } from 'src/types/params';
import { checkCompanyType } from '../../../../../utils/check-company-type';
import { DATE_TYPE } from '../../../../../types/form';
import { convertDateToFormatted } from '../../../../../utils/timestamp-to-date-format';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/New_York');

const Add: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const navigate = useNavigate();
    const companyDetails = useFinancialCompany();
    const { companyType, companyId, id } = useParams<IParams>();
    const isEditingRule = Boolean(id);
    const [loading, setLoading] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [formSchema, setFormSchema] = useState<Record<AssignPersonSchemaFields, IFormGeneratorGeneralSchemaType>>(assignPersonSchema);

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
    } = useForm<IAssignPersonFormData>({
        defaultValues: generateDefaultValueFromFormSchema(formSchema),
        mode: 'all',
        resolver: yupResolver(assignPersonValidationSchema),
    });

    const fetchTypesSelectBox = async () => {
        setFormSchema((formSchema) => ({
            ...formSchema,
            type: {
                ...formSchema.type,
                props: {
                    ...formSchema.type.props,
                    loading: true,
                },
            },
        }));
        const typesResponse = await companyService(companyType as COMPANY_TYPE_ROUTE).getAssignPositionTypes();
        const data = Object.entries(typesResponse.data.data.types).map(([value, label]) => ({
            label,
            value,
        }));

        setFormSchema((formSchema) => ({
            ...formSchema,
            type: {
                ...formSchema.type,
                data,
                props: {
                    ...formSchema.type.props,
                    loading: false,
                },
            },
        }));
    };

    const fetchPeopleSelectBox = async (search?: string) => {
        setFormSchema((formSchema) => ({
            ...formSchema,
            person_id: {
                ...formSchema.person_id,
                data: [],
                props: {
                    ...formSchema.person_id.props,
                    loading: true,
                },
            },
        }));
        const peopleResponse = await personService.selectBox({ limit: 10, ...(search?.length && { text: search }) });
        const data = peopleResponse.data.data.map(({ label, value }) => ({
            label,
            value: String(value),
        }));
        setFormSchema((formSchema) => ({
            ...formSchema,
            person_id: {
                ...formSchema.person_id,
                data,
                props: {
                    ...formSchema.person_id.props,
                    loading: false,
                },
            },
        }));
    };

    const fetchPositionsSelectBox = async (type: PERSON_TYPE, search?: string) => {
        setFormSchema((formSchema) => ({
            ...formSchema,
            person_position_id: {
                ...formSchema.person_position_id,
                data: [],
                props: {
                    ...formSchema.person_position_id.props,
                    loading: true,
                },
            },
        }));
        const positionsResponse = await positionService.selectBox({ type, limit: 10, ...(search?.length && { text: search }) });
        const data = positionsResponse.data.data.map(({ label, value }) => ({
            label,
            value: String(value),
        }));
        setFormSchema((formSchema) => ({
            ...formSchema,
            person_position_id: {
                ...formSchema.person_position_id,
                data,
                props: {
                    ...formSchema.person_position_id.props,
                    loading: false,
                    disabled: false,
                },
            },
        }));
    };

    const onFilter = (fieldName: string, value: string) => {
        const personType = getValues('type');
        switch (fieldName) {
            case 'person_id':
                fetchPeopleSelectBox(value);
                break;
            case 'person_position_id':
                fetchPositionsSelectBox(personType, value);
                break;
            default:
                break;
        }
    };

    const handleGetValues = async () => {
        setInitialLoading(true);

        try {
            setValue('company_name', companyDetails?.name || '');

            fetchTypesSelectBox();
            if (!isEditingRule) {
                fetchPeopleSelectBox();
            }

            setFormSchema((formSchema) => ({
                ...formSchema,
                person_id: {
                    ...formSchema.person_id,
                    props: {
                        ...formSchema.person_id.props,
                        onFilter,
                    },
                },
                person_position_id: {
                    ...formSchema.person_position_id,
                    props: {
                        ...formSchema.person_position_id.props,
                        onFilter,
                    },
                },
            }));
            if (isEditingRule) await getDetails();
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setInitialLoading(false);
        }
    };

    const getDetails = async () => {
        try {
            let res = await companyService(companyType as COMPANY_TYPE_ROUTE).showPerson(Number(companyId), Number(id));
            fetchPositionsSelectBox(res.data.data.type, res.data.data.person_position_name);
            setFormSchema((formSchema) => ({
                ...formSchema,
                person_id: {
                    ...formSchema.person_id,
                    data: [{ label: res.data.data.person_name, value: String(res.data.data.person_id) }],
                    props: {
                        ...formSchema.person_id.props,
                        disabled: true,
                        required: false,
                    },
                },
            }));
            reset({
                type: res.data.data.type,
                company_name: companyDetails?.name || '',
                person_id: res.data.data.person_id,
                person_position_id: res.data.data.person_position_id,
                ...(res.data.data.join_date && {
                    join_date: {
                        date: dayjs.utc(dayjs.unix(res.data.data.join_date)).toDate(),
                        dateType: res.data.data.join_date_format || DATE_TYPE.DATE,
                    },
                }),
                ...(res.data.data.end_date && {
                    end_date: {
                        date: dayjs.utc(dayjs.unix(res.data.data.end_date)).toDate(),
                        dateType: res.data.data.end_date_format || DATE_TYPE.DATE,
                    },
                }),
            });
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    const onSubmit = async (_val: IAssignPersonFormData) => {
        if (
            !checkPermissionAccess(
                checkCompanyType(
                    isEditingRule ? PERMISSION_TYPES.COMPANY_PEOPLE_EDIT : PERMISSION_TYPES.COMPANY_PEOPLE_CREATE,
                    companyType as COMPANY_TYPE_ROUTE
                )
            )
        )
            return;
        let targetApi;

        const payload: IAssignPersonToCompanyRequest = {
            type: _val.type,
            person_id: Number(_val.person_id),
            person_position_id: _val.person_position_id,
            ...(_val.join_date?.date && {
                join_date: dayjs.tz(new Date(convertDateToFormatted(_val.join_date.date, _val.join_date.dateType as DATE_TYPE) as Date).toUTCString()).unix(),
                join_date_format: _val.join_date.dateType || DATE_TYPE.DATE,
            }),
            ...(_val.end_date?.date && {
                end_date: dayjs.tz(new Date(convertDateToFormatted(_val.end_date.date, _val.end_date.dateType as DATE_TYPE) as Date).toUTCString()).unix(),
                end_date_format: _val.end_date.dateType || DATE_TYPE.DATE,
            }),
        };

        const { person_id: _, ...editPayload } = payload;
        if (isEditingRule)
            targetApi = companyService(companyType as COMPANY_TYPE_ROUTE).updateAssignedPersonFromCompany(Number(companyId), Number(id), editPayload);
        else targetApi = companyService(companyType as COMPANY_TYPE_ROUTE).assignPersonToCompany(Number(companyId), payload);

        try {
            setLoading(true);
            const response = await targetApi;
            toast.fire({
                icon: response.data.success ? TOAST_STATUS.SUCCESS : TOAST_STATUS.ERROR,
                title: response.data.message,
            });
            navigate(ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.PEOPLE.ROOT.BY_COMPANY_ID(companyType as COMPANY_TYPE_ROUTE, Number(companyId)).ABSOLUTE);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (
            !checkCompanyType(isEditingRule ? PERMISSION_TYPES.COMPANY_PEOPLE_EDIT : PERMISSION_TYPES.COMPANY_PEOPLE_CREATE, companyType as COMPANY_TYPE_ROUTE)
        ) {
            navigate(ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.PEOPLE.ROOT.BY_COMPANY_ID(companyType as COMPANY_TYPE_ROUTE, Number(companyId)).ABSOLUTE);
        }
        handleGetValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'type' && value.type) {
                fetchPositionsSelectBox(value.type);
            }
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    if (initialLoading) return <InitLoading />;

    return (
        <div className="grid justify-content-center my-8">
            <div className="xs:col:12 md:col-8 xl:col-5">
                <span className="block text-2xl font-semibold">{isEditingRule ? 'Update Assigned' : 'Assign a'} Person</span>
                <div className="mt-5">
                    <FormGenerator
                        onSubmit={handleSubmit(onSubmit)}
                        control={control}
                        errors={errors}
                        schema={formSchema}
                        noBackgroundLayout
                        setValue={setValue}
                        buttons={
                            <>
                                <Button
                                    disabled={
                                        !checkCompanyType(
                                            isEditingRule ? PERMISSION_TYPES.COMPANY_PEOPLE_EDIT : PERMISSION_TYPES.COMPANY_PEOPLE_CREATE,
                                            companyType as COMPANY_TYPE_ROUTE
                                        ) || loading
                                    }
                                    loading={loading}
                                    color="primary"
                                >
                                    {isEditingRule ? 'Update' : 'Create'}
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() =>
                                        navigate(
                                            ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.PEOPLE.ROOT.BY_COMPANY_ID(companyType as COMPANY_TYPE_ROUTE, Number(companyId))
                                                .ABSOLUTE
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default Add;
