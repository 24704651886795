import { FC } from 'react';
import { FinancialDataProvider } from './context/StateManagement';
import FinancialAddUpdateMainComponent from './components/MainComponent';

const SelectFinancialDataWrapper: FC = () => {
    return (
            <FinancialAddUpdateMainComponent />
    );
};

export default SelectFinancialDataWrapper;
