import React, { Dispatch, SetStateAction } from 'react';
import { useCheckPermissionAccess } from '../../../../hooks/useCheckPermissionAccess';
import ROUTE_CONSTANTS from '../../../../Routes/constants/route-constants';
import Button from '../../../../components/Kit/Button';
import { Column } from 'primereact/column';
import { PERMISSION_TYPES } from '../../../../enums/permissions';
import DeleteIcon from '../../../../assets/Icons/DeleteIcon';
import DashboardContentWrapper from '../../../../layouts/DashboardContentWrapper';
import Table from '../../../../components/App/Table';
import { cellRender } from './cellRender';
import { IShareHolderResponse } from '../../../../api/types/share-holders';
import { useFinancialCompanyId } from '../../../../context/companies/Companies';
import { shareHolderService } from '../../../../api/services/share-holder-service';
import { modalActions } from '../../../../context/modals';
import { MODAL_TYPES } from '../../../../types/modals';
import { getAxiosError } from '../../../../utils/get-axios-error';
import { toast } from '../../../../utils/toast';
import { TOAST_STATUS } from '../../../../constants/toast-status';
import EditIcon from 'src/assets/Icons/EditIcon';
import { useNonProfilePolicy } from '../useNonProfilePolicy';
import { useParams } from 'react-router-dom';
import { COMPANY_TYPE_ROUTE } from '../../Add/enums/company-type-route';
import { IParams } from 'src/types/params';
import { checkCompanyType } from 'src/utils/check-company-type';


const CompanyShareholders: React.FC = () => {
    useNonProfilePolicy();
    const { companyType } = useParams<IParams>();
    const service = async (companyId: number, queryParams?: any) => shareHolderService(companyType as COMPANY_TYPE_ROUTE).getAllShareHolders(companyId, queryParams);
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const companyId = useFinancialCompanyId();
    const handleRemove = (rowData: IShareHolderResponse, setTableData: Dispatch<SetStateAction<IShareHolderResponse[]>>) => {
        const handleRemoveItem = async () => {
            try {
                const response = await shareHolderService(companyType as COMPANY_TYPE_ROUTE).delete(Number(companyId), rowData.id);
                setTableData((tData) => tData.filter((data: IShareHolderResponse) => data.id !== rowData.id));
                toast.fire({
                    icon: TOAST_STATUS.SUCCESS,
                    title: response.data.message,
                });
                return Promise.resolve();
            } catch (e) {
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: getAxiosError(e).message,
                });
                return Promise.reject(getAxiosError(e));
            }
        };

        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: 'Delete Shareholder',
            text: 'Are you sure to delete this Shareholder?',
            handleAction: handleRemoveItem,
        });
    };

    const suffixCols = (data: IShareHolderResponse[], setTableData: Dispatch<SetStateAction<IShareHolderResponse[]>>) => {
        return (
            <Column
                key="Action"
                body={(rowData: IShareHolderResponse) => (
                    <>
                        {checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_SHARE_HOLDER_EDIT, companyType as COMPANY_TYPE_ROUTE)) && (
                            <Button
                                link
                                color="secondary"
                                size="m"
                                iconOnly
                                to={
                                    ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.SHAREHOLDERS.EDIT.EDIT_BY_ID(companyType as COMPANY_TYPE_ROUTE, companyId, rowData.id)
                                        .ABSOLUTE
                                }
                            >
                                <EditIcon />
                            </Button>
                        )}
                        {checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_SHARE_HOLDER_DELETE, companyType as COMPANY_TYPE_ROUTE)) && (
                            <Button color="secondary" iconOnly size="m" onClick={() => handleRemove(rowData, setTableData)}>
                                <DeleteIcon />
                            </Button>
                        )}
                    </>
                )}
            />
        );
    };

    return (
        <>
            <DashboardContentWrapper
                title="Company Shareholders"
                titleSuffix={
                    checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_SHARE_HOLDER_CREATE, companyType as COMPANY_TYPE_ROUTE)) ? (
                        <Button
                            size="s"
                            link
                            color="primary"
                            to={
                                ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.SHAREHOLDERS.ADD_NEW.ADD_NEW_BY_COMPANY_ID(companyType as COMPANY_TYPE_ROUTE, companyId).ABSOLUTE
                            }
                        >
                            Add New Shareholder
                        </Button>
                    ) : null
                }
            />
            <DashboardContentWrapper>
                {checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_SHARE_HOLDER_LIST_VIEW, companyType as COMPANY_TYPE_ROUTE)) && (
                    <Table getTableData={(params) => service(companyId, params)} cellRender={cellRender} suffixCols={suffixCols} />
                )}
            </DashboardContentWrapper>
        </>
    );
};

export default CompanyShareholders;
