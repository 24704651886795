import { FC, memo } from 'react';
import style from './AddUpdate.module.scss';
import { targetComponent } from './components/targetComponent';
import { useFinancial, useFinancialMutationStep } from 'src/context/companies/Companies';
import { useMutationInitializer } from './hooks/useMutationInitializer';
import InitLoading from '../../../../../components/App/Loading/InitLoading';
import {
    FinancialDataProvider
} from 'src/pages/Company/Show/CompanyFinancialInformation/AddUpdate/components/SelectFinancialData/context/StateManagement';

const AddUpdateFinancialInformation: FC = memo(() => {
    useMutationInitializer();
    const step = useFinancialMutationStep();
    const isLoadingFinancialTypes = useFinancial((global) => global.financialTypesLoading);

    const Component = targetComponent[step];

    if (isLoadingFinancialTypes) return <InitLoading />;

    return (
        <div className={style['financial-information']}>
            <FinancialDataProvider>
                <Component />
            </FinancialDataProvider>
        </div>
    );
})

export default AddUpdateFinancialInformation;
