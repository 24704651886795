import React, { memo, useEffect } from 'react';
import { financialActions, useFinancial } from '../../../../../context/companies/Companies';
import styles from './CompanyFinancialInformation.module.scss';
import { IFinancialInformationTypes } from '../../../../../api/types/company';
import { classNames } from 'primereact/utils';
import { toast } from '../../../../../utils/toast';
import { TOAST_STATUS } from '../../../../../constants/toast-status';
import { companyService } from '../../../../../api/services/company';
import { useParams } from 'react-router-dom';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { IParams } from 'src/types/params';

const CompanyFinancialTypes: React.FC = () => {
    const { companyType } = useParams<IParams>();
    const { companyId, selectedFinancialDataTypeId, companyFinancialTypes } = useFinancial((global) => ({
        selectedFinancialDataTypeId: global.financialTypeSelected,
        companyFinancialTypes: global.financialTypes,
        companyId: global.companyId,
    }));

    const getFinancialRowByType = async (typeId: number) => {
        financialActions.setLoadings({ financialRowsByType: true });
        let {
            data: { data: financialRowsByTypeResponse },
        } = await companyService(companyType as COMPANY_TYPE_ROUTE).getRowsByType(companyId, typeId);
        financialRowsByTypeResponse = financialRowsByTypeResponse.map((financialRowByType) => {
            delete financialRowByType.sequence;
            return financialRowByType;
        });
        financialActions.setFinancialRowsByType(financialRowsByTypeResponse);
        financialActions.setLoadings({ financialRowsByType: false });
    };

    const onClickTab = async (typeId: number | undefined) => {
        if (typeId) {
            financialActions.setSelectedFinancialType(typeId);
        } else {
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: 'TypeId Not Founded!',
            });
        }
    };

    useEffect(() => {
        getFinancialRowByType(selectedFinancialDataTypeId as number);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFinancialDataTypeId]);

    return (
        <>
            {companyFinancialTypes.length > 0 && (
                <div className={styles.headerTabsWrapper} data-cy={'financial-types'}>
                    {companyFinancialTypes?.map((tab: IFinancialInformationTypes, index: number) => (
                        <div
                            key={`financialTypes__${index}`}
                            className={classNames(styles.tab, tab.id === selectedFinancialDataTypeId && styles.activeTab)}
                            onClick={() => onClickTab(tab.id)}
                        >
                            <span>{tab.name}</span>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default memo(CompanyFinancialTypes, () => true);
