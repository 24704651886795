import { ChangeEvent, useState } from 'react';
import { useTotalSubtotalContext } from '../../../../context';
import RelatedItemCard from '../RelatedItemCard';
import { IFinancialInformationRow } from 'src/pages/Company/Show/CompanyFinancialInformation/types';
import styles from './SelectedItemsBox.module.scss';
import { useFinancialDataContext } from '../../../../../../hooks/useFinancialDataContext';
import {
    ITEM_TYPE_ENUM, ITEM_VALUE_TYPE_OPTIONS_ENUM,
} from 'src/enums/company/financialInformation/financialItem';

const SelectedItemsBox: React.FC = () => {
    const { selectedTotalSubtotal } = useTotalSubtotalContext();
    const { financialDataRows } = useFinancialDataContext();
    const [searchValue, setSearchValue] = useState('');

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const regex = new RegExp(searchValue, 'i');
    // Todo : check if the new logic is correct, commented code is old logic
    // const childrenItemIds = selectedTotalSubtotal?.children?.map((item: IFinancialInformationRow) => item.itemId) as Array<number>;
    // const listByFinancialRows = financialDataRows?.filter((item) => childrenItemIds?.includes(item.itemId));
    // old logic over

    const listByFinancialRows = financialDataRows?.filter((item) => {
        const validTypes = [
            ITEM_TYPE_ENUM.ITEM,
            ITEM_TYPE_ENUM.SUBTOTAL,
            ITEM_TYPE_ENUM.TOTAL
        ]
        const validValueTypes = [
            ITEM_VALUE_TYPE_OPTIONS_ENUM.NUMERIC
        ]
        return (
            selectedTotalSubtotal.name &&
            item.name &&
            item.name[0].id !== selectedTotalSubtotal.name[0].id &&
            validTypes.includes(item.type!) &&
            validValueTypes.includes(item.name[0]?.value_type) &&
            item.value
        );
    });
    const filteredList = listByFinancialRows?.filter((item: any) => {
        return regex.test(item.name[0].name)
    })

    return (
        <div className={styles.selectedItemsBoxWrapper} data-cy={'formula-related-items'}>
            <div className={styles.selectedItemsBoxSearch}>
                <input placeholder="Search" onChange={handleSearch} />
            </div>
            <div className={styles.selectedItemsBoxItems}>
                <div className={styles.selectedItemsBoxItemsList}>
                    {filteredList?.map((child: IFinancialInformationRow, index: number) => (
                        <RelatedItemCard key={index} {...child} />
                    ))}
                </div>
            </div>
            <div className={styles.selectedItemsBoxTipsWrapper}>
                <span className={styles.title}>tips</span>
                <div className={styles.items}>
                    <div className={styles.title}>1. We only display items with value. </div>
                    {/*<span className={styles.suffix}>{`(type @ to select an item)`}</span>*/}
                </div>
                <div className={styles.items}>
                    <div className={styles.title}>2. Title and Sub-Title items are not shown.</div>
                    {/*<span className={styles.suffix}>{`(type @ to select an item)`}</span>*/}
                </div>
            </div>
        </div>
    );
};

export default SelectedItemsBox;
