import React from 'react';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { Routes as RoutesWrapper, Route, Navigate } from 'react-router-dom';
import ROUTE_CONSTANTS from './constants/route-constants';
import NotFound from 'src/pages/NotFound';
import Forbidden from 'src/pages/Forbidden';
import AuthLayout from 'src/layouts/AuthLayout';
import DashboardLayout from '../layouts/DashboardLayout/index';
import CompanyList from 'src/pages/Company/List';
import CompanyShowProvider from 'src/pages/Company/Show/layout';
import CompanyFinancialInformation from 'src/pages/Company/Show/CompanyFinancialInformation';
import CompanyStepForms from 'src/pages/Company/Add';
import AddUpdateFinancialInformationCategories from 'src/pages/Settings/FinancialInformation/CategoriesAndSubcategoriesForm';
import CompanyProfile from 'src/pages/Company/Show/CompanyProfile';
import CompanyFinancialRatio from 'src/pages/Company/Show/CompanyFinancialRatio';
import CompanyDividends from 'src/pages/Company/Show/CompanyDividends';
import CompanyDividendsAddUpdate from 'src/pages/Company/Show/CompanyDividends/AddUpdate';
import CompanyFinancialReports from 'src/pages/Company/Show/CompanyFinancialReports';
import AddUpdateFinancialReport from 'src/pages/Company/Show/CompanyFinancialReports/AddUpdate';
import CompanyPeople from 'src/pages/Company/Show/CompanyPeople';
import AddUpdateAssignPerson from 'src/pages/Company/Show/CompanyPeople/AddUpdate';
import Login from 'src/pages/auth/Login';
import Dashboard from 'src/pages/Dashboard';
import UserManagement from 'src/pages/UserManagement';
import Users from 'src/pages/UserManagement/Users/List';
import AddUpdateUsers from 'src/pages/UserManagement/Users/AddUpdate';
import Roles from 'src/pages/UserManagement/Roles/List';
import AddUpdateRole from 'src/pages/UserManagement/Roles/AddUpdate';
import PersonManagement from 'src/pages/PersonManagement';
import Persons from 'src/pages/PersonManagement/Persons/List';
import AddUpdatePerson from 'src/pages/PersonManagement/Persons/AddUpdate';
import Positions from 'src/pages/PersonManagement/Positions/List';
import AddUpdatePositions from 'src/pages/PersonManagement/Positions/AddUpdate';
import Settings from 'src/pages/Settings';
import FinancialInformationSettings from 'src/pages/Settings/FinancialInformation';
import SectorsSetting from 'src/pages/Settings/Sectors/List';
import AddUpdateSector from 'src/pages/Settings/Sectors/AddUpdate';
import StockExchangeSetting from 'src/pages/Settings/StockExchange/List';
import AddUpdateStockExchange from 'src/pages/Settings/StockExchange/AddUpdate';
import LegalStructuresSetting from 'src/pages/Settings/LegalStructures/List';
import AddUpdateLegalStructures from 'src/pages/Settings/LegalStructures/AddUpdate';
import FinancialInformationCategories from 'src/pages/Settings/FinancialInformation/Categories/List';
import FinancialInformationSubCategories from 'src/pages/Settings/FinancialInformation/SubCategories/List';
import FinancialInformationItems from 'src/pages/Settings/FinancialInformation/Items/List';
import AddNewFinancialInformationItem from 'src/pages/Settings/FinancialInformation/Items/AddUpdate';
import ProtectedRoute from 'src/components/App/ProtectedRoute';
import EditCompany from 'src/pages/Company/Edit';
import CompanyShareholders from '../pages/Company/Show/CompanyShareholders';
import ShareHolderAddUpdate from '../pages/Company/Show/CompanyShareholders/AddUpdate';
import CompanyHoldings from 'src/pages/Company/Show/CompanyHoldings';
import HoldingAddUpdate from 'src/pages/Company/Show/CompanyHoldings/AddUpdate';
import MediaLibraryList from '../pages/Settings/MediaLibrary/List/index';
import ProtectedRouteCompanies from 'src/components/App/ProtectedCompaniesRoute';
import CompanyParentCompanyFinancial from 'src/pages/Company/Show/CompanyParentCompanyFinancial';
import ParentCompanyAddUpdate from 'src/pages/Company/Show/CompanyParentCompanyFinancial/AddUpdate';
import NewsSource from 'src/pages/Settings/NewsSource';
import NewsSourceAddUpdate from 'src/pages/Settings/NewsSource/AddUpdate';
import CompanyNewsAddUpdate from 'src/pages/Company/Show/CompanyNews/AddUpdate';
import CompanyNewsList from 'src/pages/Company/Show/CompanyNews';
import PersonShow from 'src/pages/PersonManagement/Persons/RelatedCompany';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import CommentsAddUpdate from 'src/pages/Company/Show/CompanyComments/AddUpdate';
import CompanyComments from 'src/pages/Company/Show/CompanyComments';
import UserRequestList from 'src/pages/UserManagement/Requests/List';
import CommentShow from 'src/pages/Company/Show/CompanyComments/Show';
import Feeds from 'src/pages/Settings/Feeds';
import Widgets from 'src/pages/Settings/Widgets';

const Routes: React.FC = () => {
    // # wrapper route is working as parent. in authentication ROUTE_CONSTANTS.AUTH.ROOT.RELATIVE is '/auth'
    // # as a parent route you can go to auth itself, it can have a a component or some child routes, here we have some children.
    // # one of the children defined parent route itself, it does not have any path. this way we can render some children in terms of user manually select parent route only.
    // # here we are navigating to auth/login if users select /auth only.
    // # check AuthLayout please to see the wrapper component is rendering in all auth child routes.
    // # all out routing system is working just like mentioned above using react router 6.
    // # Relative / Absolute: Relative routes are for handing route on its current level. but absolute refer to root of routing into the deepest part,
    // # so absolutes are good for navigation and relative are good for routing definitions.
    return (
        <RoutesWrapper>
            {/* # this is for case that users manually select root route. by default we redirect them into authentication process then other wrappers decide to navigate user into correct routes.  */}
            <Route path={ROUTE_CONSTANTS.ROOT.RELATIVE} element={<AuthLayout />}>
                <Route path="" element={<Navigate to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE} replace />} />
                <Route path={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE} element={<Navigate to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE} />} />
            </Route>

            {/* # Authentication flow  */}
            <Route path={ROUTE_CONSTANTS.AUTH.ROOT.RELATIVE} element={<AuthLayout />}>
                <Route path="" element={<Navigate to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE} replace />} />
                <Route path={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE} element={<Login />} />
            </Route>

            <Route path={ROUTE_CONSTANTS.ROOT.RELATIVE} element={<DashboardLayout />}>
                <Route path={ROUTE_CONSTANTS.DASHBOARD.ROOT.RELATIVE} element={<Dashboard />} />
                <Route
                    path={ROUTE_CONSTANTS.COMPANIES.ROOT.RELATIVE}
                    element={<Navigate to={ROUTE_CONSTANTS.COMPANIES.TYPE.LIST_BY_COMPANY_TYPE(COMPANY_TYPE_ROUTE.PUBLIC).ABSOLUTE} replace />}
                />
                <Route
                    path={ROUTE_CONSTANTS.COMPANIES.TYPE.ROOT.RELATIVE}
                    element={
                        <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_INFORMATION_LIST_VIEW]}>
                            <CompanyList />
                        </ProtectedRouteCompanies>
                    }
                />
                <Route
                    path={ROUTE_CONSTANTS.COMPANIES.TYPE.ADD_NEW.ROOT.RELATIVE}
                    element={
                        <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_INFORMATION_CREATE]}>
                            <CompanyStepForms />
                        </ProtectedRouteCompanies>
                    }
                />
                <Route
                    path={ROUTE_CONSTANTS.COMPANIES.TYPE.EDIT_COMPANY.ROOT.RELATIVE}
                    element={
                        <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_INFORMATION_EDIT]}>
                            <EditCompany />
                        </ProtectedRouteCompanies>
                    }
                />
                <Route
                    path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.RELATIVE}
                    element={
                        <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_INFORMATION_SINGLE_VIEW]}>
                            <CompanyShowProvider />
                        </ProtectedRouteCompanies>
                    }
                >
                    <Route path="" element={<Navigate to={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.PROFILE.ROOT.RELATIVE} replace />} />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.PROFILE.ROOT.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_INFORMATION_SINGLE_VIEW]}>
                                <CompanyProfile />
                            </ProtectedRouteCompanies>
                        }
                    />

                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.FINANCIAL_INFORMATION.ROOT.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_FI_LIST_VIEW]}>
                                <CompanyFinancialInformation />
                            </ProtectedRouteCompanies>
                        }
                    />

                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.FINANCIAL_REPORT.ROOT.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_FINANCIAL_REPORT_LIST_VIEW]}>
                                <CompanyFinancialReports />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.FINANCIAL_REPORT.ADD_NEW.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_FINANCIAL_REPORT_CREATE]}>
                                <AddUpdateFinancialReport />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.FINANCIAL_REPORT.EDIT.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_FINANCIAL_REPORT_EDIT]}>
                                <AddUpdateFinancialReport />
                            </ProtectedRouteCompanies>
                        }
                    />

                    <Route path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.FINANCIAL_RATIO.ROOT.RELATIVE} element={<CompanyFinancialRatio />} />

                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.PEOPLE.ROOT.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_PEOPLE_LIST_VIEW]}>
                                <CompanyPeople />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.PEOPLE.ROOT.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_PEOPLE_LIST_VIEW]}>
                                <CompanyPeople />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.PEOPLE.ASSIGN_PERSON.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_PEOPLE_CREATE]}>
                                <AddUpdateAssignPerson />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.PEOPLE.EDIT_ASSIGNED_PERSON.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_PEOPLE_EDIT]}>
                                <AddUpdateAssignPerson />
                            </ProtectedRouteCompanies>
                        }
                    />

                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.DIVIDENDS.ROOT.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_DIVIDEND_LIST_VIEW]}>
                                <CompanyDividends />
                            </ProtectedRouteCompanies>
                        }
                    />
                    {/*@TODO Permission is wrong , fix it after added*/}
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.SHAREHOLDERS.ROOT.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_SHARE_HOLDER_LIST_VIEW]}>
                                <CompanyShareholders />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.SHAREHOLDERS.ADD_NEW.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_SHARE_HOLDER_CREATE]}>
                                <ShareHolderAddUpdate />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.SHAREHOLDERS.EDIT.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_SHARE_HOLDER_EDIT]}>
                                <ShareHolderAddUpdate />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.DIVIDENDS.ADD_NEW.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_DIVIDEND_CREATE]}>
                                <CompanyDividendsAddUpdate />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.DIVIDENDS.EDIT.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_DIVIDEND_EDIT]}>
                                <CompanyDividendsAddUpdate />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.HOLDINGS.ROOT.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_HOLDING_LIST_VIEW]}>
                                <CompanyHoldings />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.HOLDINGS.ADD_NEW.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_HOLDING_CREATE]}>
                                <HoldingAddUpdate />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.HOLDINGS.EDIT.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_HOLDING_EDIT]}>
                                <HoldingAddUpdate />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.PARENT_COMPANY_FINANCIAL.ROOT.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_PARENT_COMPANY_FINANCIAL_LIST_VIEW]}>
                                <CompanyParentCompanyFinancial />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.PARENT_COMPANY_FINANCIAL.ADD_NEW.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_PARENT_COMPANY_FINANCIAL_CREATE]}>
                                <ParentCompanyAddUpdate />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.PARENT_COMPANY_FINANCIAL.EDIT.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_PARENT_COMPANY_FINANCIAL_EDIT]}>
                                <ParentCompanyAddUpdate />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.COMMENTS.ROOT.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_COMMENT_LIST_VIEW]}>
                                <CompanyComments />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.COMMENTS.ADD_NEW.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_COMMENT_CREATE]}>
                                <CommentsAddUpdate />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.COMMENTS.EDIT.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_COMMENT_EDIT]}>
                                <CommentsAddUpdate />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.COMMENTS.SHOW.RELATIVE}
                        element={
                            <ProtectedRouteCompanies permissions={[PERMISSION_TYPES.COMPANY_COMMENT_SINGLE_VIEW]}>
                                <CommentShow />
                            </ProtectedRouteCompanies>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.NEWS.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.NEWS_LIST]}>
                                <CompanyNewsList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.NEWS.ADD_NEW.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.NEWS_CREATE]}>
                                <CompanyNewsAddUpdate />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.NEWS.EDIT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.NEWS_EDIT]}>
                                <CompanyNewsAddUpdate />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.FEEDS_CONFIG.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.COMPANY_FEED]}>
                                <Feeds />
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route
                    path={ROUTE_CONSTANTS.USER_MANAGEMENT.ROOT.RELATIVE}
                    element={
                        <ProtectedRoute permissions={[PERMISSION_TYPES.USER_LIST_VIEW]}>
                            <UserManagement />
                        </ProtectedRoute>
                    }
                >
                    <Route path="" element={<Navigate to={ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.ROOT.ABSOLUTE} replace />} />
                    <Route
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.USER_LIST_VIEW]}>
                                <Users />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.ADD_NEW.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.USER_CREATE]}>
                                <AddUpdateUsers />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.UPDATE.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.USER_EDIT]}>
                                <AddUpdateUsers />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.USER_REQUEST.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute
                                permissions={[
                                    PERMISSION_TYPES.USER_REQUEST_PENDING_REQUEST,
                                    PERMISSION_TYPES.USER_REQUEST_APPROVED_REQUEST,
                                    PERMISSION_TYPES.USER_REQUEST_CANCELED_REQUEST,
                                ]}
                            >
                                <UserRequestList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.ROLES.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.ROLE_LIST_VIEW]}>
                                <Roles />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.ROLES.ADD_NEW.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.ROLE_CREATE]}>
                                <AddUpdateRole />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.ROLES.UPDATE.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.ROLE_EDIT]}>
                                <AddUpdateRole />
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route
                    path={ROUTE_CONSTANTS.PERSONS.ROOT.RELATIVE}
                    element={
                        <ProtectedRoute permissions={[PERMISSION_TYPES.PERSON_LIST_VIEW]}>
                            <PersonManagement />
                        </ProtectedRoute>
                    }
                >
                    <Route path="" element={<Navigate to={ROUTE_CONSTANTS.PERSONS.PEOPLE.ROOT.ABSOLUTE} replace />} />
                    <Route
                        path={ROUTE_CONSTANTS.PERSONS.PEOPLE.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.PERSON_LIST_VIEW]}>
                                <Persons />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.PERSONS.PEOPLE.SHOW.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.PERSON_LIST_VIEW]}>
                                <PersonShow />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.PERSONS.PEOPLE.ADD_NEW.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.PERSON_CREATE]}>
                                <AddUpdatePerson />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.PERSONS.PEOPLE.UPDATE.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.PERSON_EDIT]}>
                                <AddUpdatePerson />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.PERSONS.POSITION.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.POSITION_LIST_VIEW]}>
                                <Positions />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.PERSONS.POSITION.ADD_NEW.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.POSITION_CREATE]}>
                                <AddUpdatePositions />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.PERSONS.POSITION.UPDATE.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.POSITION_EDIT]}>
                                <AddUpdatePositions />
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route path={ROUTE_CONSTANTS.SETTINGS.ROOT.RELATIVE} element={<Settings />}>
                    <Route path="" element={<Navigate to={ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.ROOT.ABSOLUTE} replace />} />
                    <Route
                        path={ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[
                                PERMISSION_TYPES.SETTING_FI_CATEGORY_LIST_VIEW,
                                PERMISSION_TYPES.SETTING_FI_SUB_CATEGORY_LIST_VIEW,
                                PERMISSION_TYPES.SETTING_FI_ITEM_LIST_VIEW
                            ]}>
                                <FinancialInformationSettings />
                            </ProtectedRoute>
                        }
                    >
                        <Route path="" element={<Navigate to={ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.CATEGORIES.ROOT.ABSOLUTE} replace />} />
                        <Route
                            path={ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.CATEGORIES.ROOT.RELATIVE}
                            element={
                                <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_FI_CATEGORY_LIST_VIEW]}>
                                    <FinancialInformationCategories />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.SUB_CATEGORIES.ROOT.RELATIVE}
                            element={
                                <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_FI_SUB_CATEGORY_LIST_VIEW]}>
                                    <FinancialInformationSubCategories />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.CATEGORIES.ADD_NEW.ROOT.RELATIVE}
                            element={
                                <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_FI_CATEGORY_CREATE]}>
                                    <AddUpdateFinancialInformationCategories parent="category" />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.SUB_CATEGORIES.ADD_NEW.ROOT.RELATIVE}
                            element={
                                <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_FI_SUB_CATEGORY_CREATE]}>
                                    <AddUpdateFinancialInformationCategories parent="sub-category" />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.CATEGORIES.UPDATE.ROOT.RELATIVE}
                            element={
                                <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_FI_CATEGORY_EDIT]}>
                                    <AddUpdateFinancialInformationCategories parent="category" />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.SUB_CATEGORIES.UPDATE.ROOT.RELATIVE}
                            element={
                                <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_FI_SUB_CATEGORY_EDIT]}>
                                    <AddUpdateFinancialInformationCategories parent="sub-category" />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.ITEMS.ROOT.RELATIVE}
                            element={
                                <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_FI_ITEM_LIST_VIEW]}>
                                    <FinancialInformationItems />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTE_CONSTANTS.SETTINGS.FINANCIAL_INFORMATION.ITEMS.ADD_NEW.ROOT.RELATIVE}
                            element={
                                <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_FI_ITEM_CREATE]}>
                                    <AddNewFinancialInformationItem />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                    <Route
                        path={ROUTE_CONSTANTS.SETTINGS.SECTORS.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_SECTOR_LIST_VIEW]}>
                                <SectorsSetting />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.SETTINGS.SECTORS.ADD_NEW.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_SECTOR_CREATE]}>
                                <AddUpdateSector />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.SETTINGS.SECTORS.UPDATE.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_SECTOR_EDIT]}>
                                <AddUpdateSector />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.SETTINGS.STOCK_EXCHANGE.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_STOCK_EXCHANGE_LIST_VIEW]}>
                                <StockExchangeSetting />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.SETTINGS.STOCK_EXCHANGE.ADD_NEW.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_STOCK_EXCHANGE_CREATE]}>
                                <AddUpdateStockExchange />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.SETTINGS.STOCK_EXCHANGE.UPDATE.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_STOCK_EXCHANGE_EDIT]}>
                                <AddUpdateStockExchange />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.SETTINGS.LEGAL_STRUCTURES.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_LEGAL_STRUCTURES_LIST_VIEW]}>
                                <LegalStructuresSetting />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.SETTINGS.LEGAL_STRUCTURES.ADD_NEW.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_LEGAL_STRUCTURES_CREATE]}>
                                <AddUpdateLegalStructures />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.SETTINGS.LEGAL_STRUCTURES.UPDATE.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_LEGAL_STRUCTURES_EDIT]}>
                                <AddUpdateLegalStructures />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.SETTINGS.MEDIA_LIBRARY.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_MEDIA_LIBRARY_LIST_VIEW]}>
                                <MediaLibraryList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.SETTINGS.NEWS_SOURCE.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_NEWS_SOURCE_LIST_VIEW]}>
                                <NewsSource />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.SETTINGS.NEWS_SOURCE.ADD_NEW.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_NEWS_SOURCE_CREATE]}>
                                <NewsSourceAddUpdate />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.SETTINGS.NEWS_SOURCE.UPDATE.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.SETTING_NEWS_SOURCE_EDIT]}>
                                <NewsSourceAddUpdate />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.SETTINGS.FEEDS_CONFIG.ROOT.RELATIVE}
                        element={
                            <ProtectedRoute permissions={[PERMISSION_TYPES.COMPANY_FEED]}>
                                <Feeds />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTE_CONSTANTS.SETTINGS.WIDGETS.ROOT.RELATIVE}
                        element={
                            // todo: add permission
                            <ProtectedRoute permissions={[]}>
                                <Widgets />
                            </ProtectedRoute>
                        }
                    />
                </Route>
            </Route>
            <Route path={ROUTE_CONSTANTS.ROOT_STAR.ABSOLUTE} element={<NotFound />} />
            <Route path={ROUTE_CONSTANTS.NOT_FOUND.RELATIVE} element={<NotFound />} />
            <Route path={ROUTE_CONSTANTS.FORBIDDEN.RELATIVE} element={<Forbidden />} />
            <Route
                path={ROUTE_CONSTANTS.SENTRY_TEST.RELATIVE}
                element={
                    <div>
                        <button
                            onClick={() => {
                                throw new Error('send sentry an exception');
                            }}
                        >
                            test sentry is working
                        </button>
                    </div>
                }
            />
        </RoutesWrapper>
    );
};

export default Routes;
