import { useState } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import styles from './AddNewFinancialDateForm.module.scss';
import { Dropdown } from 'primereact/dropdown';
import Button from 'src/components/Kit/Button';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { UUIDv4 } from 'src/utils/uuid-generator';
import CloseIcon from 'src/assets/Icons/CloseIcon';
import { DATE_TYPE_OPTIONS } from '../../../../../enums';
import { FINANCIAL_DATE_QUARTER_DICTIONARY, quarters, years } from '../../../../../constants';
import { useFinancialDataContext } from '../../hooks/useFinancialDataContext';
import { IFinancialActiveDate, ISelectedFinancialDate } from '../../../../../types';
import { FINANCIAL_INFORMATION_STATUS } from 'src/enums/company/financialInformation/financialItem';
import { companyService } from 'src/api/services/company';
import { getAxiosError } from 'src/utils/get-axios-error';
import { financialActions, useFinancialCompanyId, useFinancialSelectedType } from '../../../../../../../../../context/companies/Companies';
import { useParams } from 'react-router-dom';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { IParams } from 'src/types/params';


const AddNewFinancialDateForm: React.FC<{ handleCloseSidePanel: () => void }> = ({ handleCloseSidePanel }) => {
    const { companyType } = useParams<IParams>();
    const companyId = useFinancialCompanyId();
    const selectedFinancialDataTypeId = useFinancialSelectedType();
    const { financialDates, handleGetAllDates } = useFinancialDataContext();
    const [dateType, setDateType] = useState<DATE_TYPE_OPTIONS.ANNUAL | DATE_TYPE_OPTIONS.QUARTER>(DATE_TYPE_OPTIONS.QUARTER);
    const [selectedQuarter, setSelectedQuarter] = useState<string>();
    const [selectedYear, setSelectedYear] = useState<string>();
    const [selectedFinancialDate, setSelectedFinancialDate] = useState<ISelectedFinancialDate[]>([]);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    const handleChangeDateType = (type: DATE_TYPE_OPTIONS.ANNUAL | DATE_TYPE_OPTIONS.QUARTER) => {
        setDateType(type);
        if (type === DATE_TYPE_OPTIONS.ANNUAL) setSelectedQuarter(undefined);
    };

    const handleFormValidation = () => {
        switch (dateType) {
            case DATE_TYPE_OPTIONS.ANNUAL:
                if (!selectedYear) return true;
                else return false;
            case DATE_TYPE_OPTIONS.QUARTER:
                if (!selectedYear || !selectedQuarter) return true;
                else return false;
            default:
                return true;
        }
    };

    const handleAddDate = () => {
        let tempSelectedFinancialDate = structuredClone(selectedFinancialDate);
        const tempDate = {
            selectedYear,
            selectedType: dateType,
            ...(selectedQuarter && { selectedQuarter }),
            uniqueId: UUIDv4(),
        } as ISelectedFinancialDate;

        // Hint: Checking if admin is adding redundant item or not
        if (tempDate.selectedType === DATE_TYPE_OPTIONS.ANNUAL) {
            if (tempSelectedFinancialDate.find((sd: ISelectedFinancialDate) => sd.selectedYear === tempDate.selectedYear)) {
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: 'You are adding redundant date',
                });
                return;
            }
        } else {
            if (
                tempSelectedFinancialDate.find(
                    (sd: ISelectedFinancialDate) => sd.selectedYear === tempDate.selectedYear && sd.selectedQuarter === tempDate.selectedQuarter
                )
            ) {
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: 'You are adding redundant date',
                });
                return;
            }
        }

        // Updating the state
        tempSelectedFinancialDate = [...tempSelectedFinancialDate, tempDate];
        setSelectedFinancialDate(tempSelectedFinancialDate);

        toast.fire({
            icon: TOAST_STATUS.SUCCESS,
            title: 'New Date Added!',
        });
    };

    const handleRemoveDate = (sDate: ISelectedFinancialDate) => {
        let tempSelectedFinancialDate = structuredClone(selectedFinancialDate);

        // Finding the element admin wants to remove
        tempSelectedFinancialDate = tempSelectedFinancialDate.filter((sd: ISelectedFinancialDate) => sd.uniqueId !== sDate.uniqueId);

        // Updating the state
        setSelectedFinancialDate(tempSelectedFinancialDate);

        toast.fire({
            icon: TOAST_STATUS.SUCCESS,
            title: 'Date removed successfully!',
        });
    };

    const handleSubmit = async () => {
        const financialDatesLength = financialDates.length;
        let tempData = {} as { timespan: IFinancialActiveDate[] };

        tempData = {
            timespan: selectedFinancialDate.map((date: ISelectedFinancialDate) => {
                return {
                    year: +date.selectedYear,
                    quarter: date.selectedQuarter ? +date.selectedQuarter : null,
                    status: FINANCIAL_INFORMATION_STATUS.NOT_APPROVED,
                };
            }),
        };

        setSubmitLoading(true);
        try {
            await companyService(companyType as COMPANY_TYPE_ROUTE).addFinancialInformationDates(companyId, tempData);
            await handleGetAllDates(selectedFinancialDataTypeId);

            if (financialDatesLength === 0) financialActions.setFinancialActiveDate(tempData.timespan[0]);
            handleCloseSidePanel();
            setSelectedFinancialDate([]);
            setDateType(DATE_TYPE_OPTIONS.QUARTER);
            setSelectedQuarter(undefined);
            setSelectedYear(undefined);

            toast.fire({
                icon: TOAST_STATUS.SUCCESS,
                title: 'Dates saved!',
            });
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setSubmitLoading(false);
        }
    };

    return (
        <div className={styles.financialDataSidePanelBody}>
            <div className={styles.radioButtonsWrapper}>
                <div onClick={() => handleChangeDateType(DATE_TYPE_OPTIONS.QUARTER)}>
                    <RadioButton name={DATE_TYPE_OPTIONS.QUARTER} value={DATE_TYPE_OPTIONS.QUARTER} checked={dateType === DATE_TYPE_OPTIONS.QUARTER} />
                    <span className="ml-1" data-cy={'radio_financial-quarter'}>
                        Quarter
                    </span>
                </div>
                <div onClick={() => handleChangeDateType(DATE_TYPE_OPTIONS.ANNUAL)}>
                    <RadioButton
                        className="ml-3"
                        value={DATE_TYPE_OPTIONS.ANNUAL}
                        name={DATE_TYPE_OPTIONS.ANNUAL}
                        checked={dateType === DATE_TYPE_OPTIONS.ANNUAL}
                    />
                    <span className="ml-1" data-cy={'radio_financial-annual'}>
                        Annual
                    </span>
                </div>
            </div>
            <div className={styles.actionWrapper}>
                <Dropdown
                    value={selectedYear}
                    data-cy={'selectbox_financial-year'}
                    options={years().sort((a, b) => +b - +a)}
                    onChange={(e) => setSelectedYear(e.value)}
                    placeholder="Select Year"
                />
            </div>
            <div className={styles.actionWrapper}>
                <Dropdown
                    optionLabel="label"
                    optionValue="value"
                    value={selectedQuarter}
                    options={quarters}
                    onChange={(e) => setSelectedQuarter(e.value)}
                    placeholder="Select Quarter"
                    disabled={dateType === DATE_TYPE_OPTIONS.ANNUAL}
                />
            </div>
            <div className={styles.actionWrapper}>
                <Button
                    data-cy={'btn_add-date'}
                    customStyle={{ width: '100%' }}
                    onClick={handleAddDate}
                    color="secondary"
                    size="s"
                    disabled={handleFormValidation()}
                >
                    Add
                </Button>
            </div>
            <div className={styles.cardsWrapper}>
                {selectedFinancialDate.length > 0 && (
                    <>
                        <span className={styles.title}>Your Selected Time</span>
                        <div className={styles.selectedDateWrapper}>
                            {selectedFinancialDate.map((sd: any, index: number) => (
                                <div key={index} className={styles.card}>
                                    <span>
                                        {sd.selectedYear} / {sd.selectedQuarter ? FINANCIAL_DATE_QUARTER_DICTIONARY[sd.selectedQuarter] : 'Annual'}
                                    </span>
                                    <div onClick={() => handleRemoveDate(sd)}>
                                        <CloseIcon />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
            <div className={styles.buttonWrapper}>
                <Button
                    customStyle={{ width: '100%', marginBottom: '1rem', height: '40px', display: 'flex', alignItems: 'center' }}
                    disabled={selectedFinancialDate.length <= 0 || submitLoading}
                    onClick={handleSubmit}
                    color="primary"
                    size="s"
                    loading={submitLoading}
                    data-cy={'btn_submit-date'}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default AddNewFinancialDateForm;
