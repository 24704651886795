import React, { useState } from 'react';
import style from './InitialMode.module.scss';
import Button from 'src/components/Kit/Button';
import { classNames } from 'primereact/utils';
import { AddNewItemInSheetImage } from 'src/assets/Images/FinancialInformationLandingImage';
import { ISidePanelStateType } from 'src/types/sidePanel';
import { SIDE_PANEL_OPTIONS } from '../../../../../enums';
import SidePanel from 'src/components/App/SidePanel';
import AddNewFinancialDateForm from '../AddNewFinancialDateForm';
import { financialActions } from '../../../../../../../../../context/companies/Companies';

const InitialMode: React.FC = () => {
    const [sidePanelState, setSidePanelState] = useState<ISidePanelStateType>({
        type: undefined,
        show: false,
        itemType: undefined,
        itemId: undefined,
    });

    const handleCloseSidePanel = () => {
        setSidePanelState({
            type: undefined,
            show: false,
            itemType: undefined,
            itemId: undefined,
        });
    };

    const handleAddNewDate = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        setSidePanelState({
            type: SIDE_PANEL_OPTIONS.ADD_NEW_DATE,
            show: true,
            itemType: undefined,
            itemId: undefined,
        });
    };

    return (
        <section className={style['initial-mode-wrapper']}>
            <div>
                <AddNewItemInSheetImage />
            </div>
            <span className={classNames(style['initial-mode-wrapper__title'], 'mt-5')}>Add Financial Date</span>
            <span className={style['initial-mode-wrapper__text']}>Specify the date range you want to enter information</span>
            <div className={style['initial-mode-wrapper__buttons']}>
                <div className={style['initial-mode-wrapper__buttons__button']}>
                    <Button onClick={financialActions.mutationStepBack} color="secondary" size="m" customStyle={{ display: 'flex', alignItems: 'center' }}>
                        Back
                    </Button>
                    <Button
                        data-cy={'btn_add-financial-date'}
                        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => handleAddNewDate(e)}
                        color="primary"
                        size="m"
                        customStyle={{ display: 'flex', alignItems: 'center' }}
                    >
                        <i className="ts-plus" style={{ marginRight: '8px' }} />
                        Add Financial Date
                    </Button>
                </div>
            </div>
            <SidePanel show={sidePanelState.show} onHide={handleCloseSidePanel} title={'Add Financial Date'}>
                <AddNewFinancialDateForm handleCloseSidePanel={handleCloseSidePanel} />;
            </SidePanel>
        </section>
    );
};

export default InitialMode;
