import { useContext } from 'react';
import { FinancialDataContext } from '../context/StateManagement';

const useFinancialDataContext = () => {
    const context = useContext(FinancialDataContext);
    if (!context) {
        throw new Error('You are outside of this context area!');
    }
    return context;
};

export { useFinancialDataContext };