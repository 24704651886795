import { ModalProps } from 'src/types/modals';
// import Button from '../Button';
import style from './CustomDialog.module.scss';
import { ReactNode, useState } from 'react';

export interface CustomDialogProps {
    text: string;
    children?: ReactNode;
    footer?: ReactNode;
    handleAction?: () => Promise<void> | void;
    autoClose?: boolean;
    actionButtonText?: string;
    cancelButtonText?: string;
    disableCancelButton?: boolean;
    actionButtonColor?: 'primary' | 'secondary' | 'error' | 'success' | 'tertiary' | 'danger';
}

const CustomDialog: React.FC<ModalProps<CustomDialogProps>> = ({
    data: { children,footer, text, handleAction, actionButtonText, actionButtonColor, cancelButtonText, disableCancelButton = false, autoClose = true },
    closeModal,
}) => {
    const [loading, setLoading] = useState<boolean>();

    const handleClick = async () => {
        setLoading(true);
        handleAction && (await handleAction());
        setLoading(false);
        autoClose && closeModal();
    };

    return (
        <div className={style.wrapper}>
            <div className={style.body}>{children ? children : <span>{text}</span>}</div>
            {!!footer && <>
                <div className={style.footer}>
                    {footer}
                </div>
            </>}
            {/*<div className={style.footer}>*/}
            {/*    {}*/}
            {/*    /!*{!disableCancelButton && (*!/*/}
            {/*    /!*    <Button disabled={false} color="secondary" onClick={() => closeModal()}>*!/*/}
            {/*    /!*        {cancelButtonText || 'No, Canceled'}*!/*/}
            {/*    /!*    </Button>*!/*/}
            {/*    /!*)}*!/*/}
            {/*    /!*<Button disabled={loading} color={actionButtonColor || 'danger'} loading={loading} onClick={handleAction ? handleClick : () => closeModal()}>*!/*/}
            {/*    /!*    {actionButtonText || "Yes, I'm Sure"}*!/*/}
            {/*    /!*</Button>*!/*/}
            {/*</div>*/}
        </div>
    );
};

export default CustomDialog;
