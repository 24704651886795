import React, { memo } from 'react';
import PrefixInputNumber from 'src/components/Kit/PrefixInputNumber';
import { useTotalSubtotalContext } from '../../context';
import { InputNumberChangeParams } from 'primereact/inputnumber';
import { financialInformationLegitFractions } from 'src/pages/Company/Show/CompanyFinancialInformation/constants';
import { useFinancial } from '../../../../../../../../../../../context/companies/Companies';
import { RATIO_NUMBER_TYPE } from '../../../../../../../../../../../api/types/company';

const CustomValue: React.FC = () => {
    const { currency } = useFinancial((global) => ({
        currency: global.financialSelectedCurrency,
    }));
    const { selectedTotalSubtotal, customValue, setCustomValue } = useTotalSubtotalContext();
    const isNotNull = Boolean(typeof parseInt(customValue) === "number" || selectedTotalSubtotal.value);
    return (
        <div style={{ padding: '24px 16px' }}>
            <PrefixInputNumber
                data-cy={'input_custom-value'}
                {...(isNotNull ? { value: customValue } : { value: undefined })}
                style={{ width: '100%' }}
                onChange={(e: InputNumberChangeParams) => setCustomValue(e.value)}
                placeholder={isNotNull ? '-' : ''}
                prefix={selectedTotalSubtotal.number_type === RATIO_NUMBER_TYPE.CURRENCY && <div>{currency?.short_name}</div>}
                maxFractionDigits={financialInformationLegitFractions}
            />
        </div>
    );
};

export default memo(CustomValue);
