import isEqual from 'fast-deep-equal';
import { omit } from 'lodash';

export const bigDataUtils = {
    isDataEqual : (value1: any, value2: any) => {
        return isEqual(value1, value2)
    },
    omitObjKeyFromArray : (arr : any, key: string) => {
        return arr.map((obj: any) => omit(obj, key));
    }
}