import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { IBaseFinancialInformationValueByTypeResponse, IFinancialInformationTypes } from 'src/api/types/company';
import { COMPANY_FINANCIAL_INFORMATION_STATE, MUTATION_FINANCIAL_STEP } from 'src/pages/Company/Show/CompanyFinancialInformation/enums';
import { ICompanyDetails, IFinancialInformationSelectedCurrency } from 'src/pages/Company/Show/types';
import { IFinancialActiveDate } from 'src/pages/Company/Show/CompanyFinancialInformation/types';
import { FinancialState } from 'src/context/companies/CompanyStateTypes';

const initialState: FinancialState = {
    loadings: {
        financialDates: false,
        financialRowsByType: false,
        financialRowsByDate: false,
    },
    company: undefined,
    companyId: 0,
    companyIsLoading: true,
    canChangeBasic: undefined,
    financialTypes: [],
    financialTypesLoading: true,
    financialTypeSelected: 1,
    financialColumns: undefined,
    financialRowsByType: undefined,
    financialRowsByDate: undefined,
    financialTemplateId: undefined,
    financialYearEnd: undefined,
    financialDates: [],
    financialActiveDate: undefined,
    financialSelectedCurrency: undefined,
    financialInformationState: COMPANY_FINANCIAL_INFORMATION_STATE.VIEW_MODE,
    mutationStep: MUTATION_FINANCIAL_STEP.LANDING,
    mustUseDraft: false,
    activeDate: undefined,
    currentFinancialInformation: {},
    disableAddRowButtons: false
};

export const useFinancial = create(devtools<FinancialState>(() => initialState, { name: 'Financial' }));
export const useFinancialCompanyId = () => useFinancial((global) => global.companyId);
export const useFinancialCompany = () => useFinancial((global) => global.company);
export const useFinancialSelectedType = () => useFinancial((global) => global.financialTypeSelected);
export const useFinancialMutationStep = () => useFinancial((global) => global.mutationStep);
export const useFinancialTemplateId = () => useFinancial((global) => global.financialTemplateId);
export const useCurrentFinancialInformation = () => useFinancial((global) => global.currentFinancialInformation);
export const useDisableAddRowButtons = () => useFinancial((global) => global.disableAddRowButtons);
export const useFinancialActiveDate = () => useFinancial((global) => global.financialActiveDate);
export const financialActions = {
    setCurrentFinancialInfo(value: any) {
        useFinancial.setState({
            currentFinancialInformation: value,
        })
    },
    updateDisableAddRowButtons: (value: boolean) => {
        useFinancial.setState({
            disableAddRowButtons: value
        })
    },
    addCompanyId(companyId: ICompanyDetails['id']) {
        useFinancial.setState(initialState);
        useFinancial.setState({
            companyId,
        });
    },
    addCompany(company: ICompanyDetails) {
        useFinancial.setState({
            companyIsLoading: false,
            financialInformationState: company?.can_change ? COMPANY_FINANCIAL_INFORMATION_STATE.EDIT_MODE : COMPANY_FINANCIAL_INFORMATION_STATE.VIEW_MODE,
            company,
        });
    },
    editCompany(company: ICompanyDetails) {
        // @TODO It's must be only take Partial<ICompanyDetails>
        useFinancial.setState({
            company,
        });
    },
    addTypes(financialTypes: Array<IFinancialInformationTypes>) {
        useFinancial.setState({
            financialTypes,
        });
    },
    setSelectedFinancialType(financialTypeId: IFinancialInformationTypes['id']) {
        useFinancial.setState({
            financialTypeSelected: financialTypeId,
        });
    },
    setFinancialInformationState(informationState: COMPANY_FINANCIAL_INFORMATION_STATE) {
        useFinancial.setState({
            financialInformationState: informationState,
        });
    },
    setFinancialLoading(financialTypesLoading: boolean) {
        useFinancial.setState({
            financialTypesLoading,
        });
    },
    setLoadings(partial: Partial<FinancialState['loadings']>) {
        useFinancial.setState({
            loadings: { ...useFinancial.getState().loadings, ...partial },
        });
    },
    setFinancialTemplateId(templateId: number) {
        useFinancial.setState({
            financialTemplateId: templateId,
        });
    },
    setMutationStep(mutationStep: MUTATION_FINANCIAL_STEP) {
        useFinancial.setState({
            mutationStep,
        });
    },
    mutationStepBack() {
        useFinancial.setState({
            mutationStep: useFinancial.getState().mutationStep - 1,
        });
    },
    setFinancialYearEnd(financialYearEnd: string) {
        useFinancial.setState({
            financialYearEnd,
        });
    },
    addFinancialDates(financialDates: FinancialState['financialDates']) {
        useFinancial.setState({
            financialDates,
        });
    },
    setFinancialActiveDate(financialActiveDate: IFinancialActiveDate) {
        useFinancial.setState({
            financialActiveDate,
        });
    },
    setCanChangeBasics(canChangeBasic: boolean) {
        useFinancial.setState({
            canChangeBasic,
        });
    },
    setFinancialSelectedCurrency(financialSelectedCurrency?: IFinancialInformationSelectedCurrency) {
        useFinancial.setState({
            financialSelectedCurrency,
        });
    },
    setFinancialRowsByType(financialRowsByType: IBaseFinancialInformationValueByTypeResponse[]) {
        useFinancial.setState({
            financialColumns: makeFinancialValuesColumns(financialRowsByType[0]),
            financialRowsByType: financialRowsByType.map((financialRow) => {
                delete financialRow.sequence;
                return financialRow;
            }),
        });
    },
    setMustUseDraft(mustUseDraft: boolean) {
        useFinancial.setState({
            mustUseDraft,
        });
    },
};

const makeFinancialValuesColumns = (prototypeField: IBaseFinancialInformationValueByTypeResponse) => [
    ...Object.keys(prototypeField || {})
        .filter(
            (column) =>
                ![
                    'value_type',
                    'name',
                    'type',
                    'financial_type_id',
                    'must_show_currency',
                    'company_financial_information_type_id',
                    'number_type',
                    'category',
                    'sub_category',
                ].includes(column)
        )
        .sort((a: string, b: string) => {
            let aQuarter;
            let bQuarter;
            const aSplitArray = a.split('/');
            const bSplitArray = b.split('/');
            const aYear = parseInt(aSplitArray[0]);
            const bYear = parseInt(bSplitArray[0]);

            if (aSplitArray[1]) aQuarter = parseInt(aSplitArray[1][1]);
            if (bSplitArray[1]) bQuarter = parseInt(bSplitArray[1][1]);

            if (bYear > aYear) return 1;
            else if (bYear === aYear && bQuarter && aQuarter) {
                if (bQuarter > aQuarter) return 1;
                else return -1;
            } else return -1;
        })
        .map((column) => {
            return {
                field: column,
                header: column,
                sortable: false,
                filterable: false,
                visible: true,
                showCurrency: true,
            };
        }),
];
