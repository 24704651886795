import React, { memo, useEffect, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardTitle from 'src/components/App/DashboardTitle';
import InitLoading from 'src/components/App/Loading/InitLoading';
import Button from 'src/components/Kit/Button';
import { TOAST_STATUS } from 'src/constants/toast-status';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import style from './Widgets.module.scss';
import { IParams } from 'src/types/params';
import DraggableList from 'src/components/Kit/DraggableList/DraggableList';
import { widgetService } from 'src/api/services/widget-service';
import { WidgetData } from 'src/types/setting';


// DraggableItem component with proper typings
interface DraggableItemProps {
    data: WidgetData;
    onChange: (key: string, value: any, id: number|string) => void;
}

enum WIDGET_VISIBILITY {
    VISIBLE = 2,
    HIDDEN = 1,
}

const DraggableItem: React.FC<DraggableItemProps> = memo(({ data, onChange }) => {
    // @ts-ignore
    const initialVisibility = (data.visibility === WIDGET_VISIBILITY.VISIBLE)
    const [visibility, setVisibility] = useState<boolean>(initialVisibility);

    const handleVisibilityChange = (value?: boolean) => {
        const newVisibility = value || !visibility;
        setVisibility(newVisibility);
        onChange('visibility', (newVisibility ? WIDGET_VISIBILITY.VISIBLE: WIDGET_VISIBILITY.HIDDEN), data.id);
    };
    return (<>
        <div className={style.card}>
            <div className={style.cardHeader}>
                <h3 className={style.cardTitle}>{data.name}</h3>
            </div>
            <div className={style.cardBody}>
                <p>Order: {data.order}</p>
                <div className={style.cardActions}>
                    <div
                        className={style.visibilityToggle}
                        onClick={() => handleVisibilityChange()}
                    >
                        <Checkbox
                            value={visibility}
                            checked={Boolean(visibility)}
                        />
                        <label>{visibility ? 'Visible' : 'Hidden'}</label>
                    </div>
                </div>
            </div>
        </div>

    </>);
});

const Widgets: React.FC = memo(() => {
    const { companyId } = useParams<IParams>();
    const isCompanyFeedsConfig = Boolean(companyId);
    const navigate = useNavigate();
    const [widgets, setWidgets] = useState<WidgetData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [updateLoading, setUpdateLoading] = useState<boolean>(false);

    const fetchWidgetData = async () => {
        try {
            setLoading(true);
            const response = await widgetService.getAllWidgets();
            setWidgets(response.data.data || []);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setLoading(false);
        }
    };

    const onSaveData = async (data: WidgetData[]) => {
        if (!data.length) return;
        try {
            setUpdateLoading(true);
            const changedWidgetData = data.map(item => {
                return {
                    ...item,
                    // todo : if visibility got more than 2 enums make a type for it
                    visibility: item.visibility
                }
            }) as any[];
            const response = await widgetService.updateWidgets({
                widgets: changedWidgetData
            });
            toast.fire({
                icon: TOAST_STATUS.SUCCESS,
                title: response.data.message,
            });
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setUpdateLoading(false);
        }
    };

    useEffect(() => {
        fetchWidgetData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) return <InitLoading />;

    return (
        <>
            {!isCompanyFeedsConfig && (
                <DashboardTitle
                    title="Widgets Setting"
                    titleSuffix={
                        <div style={{ marginLeft: 'auto' }}>
                            <Button size="s" type="button" onClick={() => navigate(-1)}>
                                Cancel
                            </Button>
                            <Button
                                size="s"
                                disabled={updateLoading}
                                loading={updateLoading}
                                color="primary"
                                onClick={() => onSaveData(widgets)}
                            >
                                Save Changes
                            </Button>
                        </div>
                    }
                />
            )}

            <DashboardContentWrapper>
                <DraggableList
                    initialItems={widgets.map((widget) => ({
                        id: widget.id.toString(),
                        content: <DraggableItem data={widget} onChange={(key, value, id) => {
                            const newWidgets = widgets.map((widget) => {
                                if (id === widget.id) {
                                    return {
                                        ...widget,
                                        [key]: value
                                    }
                                }
                                return widget
                            })
                            setWidgets(newWidgets);
                        }} />,
                        data: widget,
                    }))}
                    onOrderChanged={(newOrder) => {
                        const newWidgetsOrder = newOrder.map((item, index) => {
                            return {
                                ...item.data,
                                order: index + 1
                            }
                        });
                        setWidgets(newWidgetsOrder);
                    }}
                />
            </DashboardContentWrapper>
        </>
    );
});

export default Widgets;
