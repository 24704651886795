import React, { useState } from 'react';
import styles from './CompanyProfile.module.scss';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import Accordion from 'src/components/Kit/Accordion';
import BasicInformation from './components/BasicInformation';
import OtherInformation from './components/OtherInformation';
import ContactInformation from './components/ContactInformation';
import StatusDropDown from 'src/components/Kit/StatusDropDown';
import { toast } from 'src/utils/toast';
import { getAxiosError } from 'src/utils/get-axios-error';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { DropdownChangeParams } from 'primereact/dropdown';
import { companyService } from 'src/api/services/company';
import { statusDropDownOptions } from '../../List/constants/status-dropdown-options';
import { COMPANY_STATUS } from 'src/api/types/company';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { useFinancialCompany, useFinancialCompanyId } from '../../../../context/companies/Companies';
import Button from '../../../../components/Kit/Button';
import ROUTE_CONSTANTS from '../../../../Routes/constants/route-constants';
import { COMPANY_TYPE } from '../../Add/enums/company-type';
import VisitIcon from '../../../../assets/Icons/Visit';
import { ApiConfig } from '../../../../api/config';
import Link from '../../../../components/Kit/Link';
import { useParams } from 'react-router-dom';
import { COMPANY_TYPE_ROUTE } from '../../Add/enums/company-type-route';
import { IParams } from 'src/types/params';
import { checkCompanyType } from 'src/utils/check-company-type';
import { companyTitleByType } from 'src/constants/company/company-title-by-type';

const CompanyProfile: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const { companyType } = useParams<IParams>();
    const disableStatusDropdown = !(
        checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_APPROVE, companyType as COMPANY_TYPE_ROUTE)) ||
        checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_NOT_APPROVE, companyType as COMPANY_TYPE_ROUTE))
    );
    const [statusLoading, setStatusLoading] = useState<boolean>(false);
    const companyDetails = useFinancialCompany();
    const companyId = useFinancialCompanyId();
    const [companyStatus, setCompanyStatus] = useState<COMPANY_STATUS>(companyDetails!.status);
    const showStatusDropdown = companyDetails?.type !== COMPANY_TYPE.NON_PROFILE;
    const singularTitle = companyTitleByType[companyType as COMPANY_TYPE_ROUTE].singular;

    const handleChangeStatus = async (event: DropdownChangeParams) => {
        setStatusLoading(true);
        try {
            const response = await companyService(companyType as COMPANY_TYPE_ROUTE).changeStatus(companyId, { status: Number(event.target.value) });
            if (response.data.success) {
                toast.fire({
                    icon: TOAST_STATUS.SUCCESS,
                    title: response.data.message,
                });
                setCompanyStatus((prev) => (prev === COMPANY_STATUS.APPROVED ? COMPANY_STATUS.NOT_APPROVED : COMPANY_STATUS.APPROVED));
            } else {
                toast.fire({
                    icon: TOAST_STATUS.WARNING,
                    title: response.data.message,
                });
            }
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setStatusLoading(false);
        }
    };

    return (
        <DashboardContentWrapper noPadding>
            <div className={styles.header}>
                <div className={styles.headerTitle}>
                    <h3>Company Detail</h3>
                    <div className={styles.headerDesc}>
                        <span>{companyDetails?.name}</span>
                        {companyDetails?.status === COMPANY_STATUS.APPROVED && (
                            <div>
                                <Link href={`${ApiConfig.websiteUrl}/companies/${companyDetails?.slug}`} target={'_blank'} icon={<VisitIcon />}>
                                    See Website
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.headerSuffix}>
                    <div className={'flex justify-center items-center'}>
                        <div style={{ marginRight: 8 }}>
                            {showStatusDropdown && (
                                <StatusDropDown
                                    label="Company Status"
                                    loading={statusLoading}
                                    dropDownProps={{
                                        options: statusDropDownOptions,
                                        onChange: handleChangeStatus,
                                        placeholder: 'Company Status',
                                        value: String(companyStatus),
                                        disabled: disableStatusDropdown || statusLoading,
                                        optionDisabled: (option) => {
                                            if (option.value === String(COMPANY_STATUS.APPROVED)) {
                                                return !checkPermissionAccess(
                                                    checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_APPROVE, companyType as COMPANY_TYPE_ROUTE)
                                                );
                                            }
                                            if (option.value === String(COMPANY_STATUS.NOT_APPROVED)) {
                                                return !checkPermissionAccess(
                                                    checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_NOT_APPROVE, companyType as COMPANY_TYPE_ROUTE)
                                                );
                                            }
                                            return false;
                                        },
                                    }}
                                />
                            )}
                        </div>
                        {companyDetails?.id &&
                            checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_INFORMATION_EDIT, companyType as COMPANY_TYPE_ROUTE)) && (
                                <Button
                                    link
                                    color="primary"
                                    size="l"
                                    to={ROUTE_CONSTANTS.COMPANIES.TYPE.SHOW.EDIT.ROOT.BY_COMPANY_ID(companyType as COMPANY_TYPE_ROUTE, companyDetails?.id).ABSOLUTE}
                                >
                                    Edit Company Profile
                                </Button>
                            )}
                    </div>
                </div>
            </div>
            <div className={styles.body}>
                <Accordion title="Basic Information">
                    <BasicInformation />
                </Accordion>
                <Accordion title="Other Information">
                    <OtherInformation />
                </Accordion>
                <Accordion title="Contact Information">
                    <ContactInformation />
                </Accordion>
            </div>
        </DashboardContentWrapper>
    );
};

export default CompanyProfile;
