import { ModalsList } from "src/components/App/Modal/modalsList";

export enum MODAL_TYPES {
    CONFIRMATION_MODAL = 'confirmationModal',
    CUSTOM_MODAL = 'customModal',
    FILE_MODAL = 'fileModal',
    FINANCIAL_INFORMATION_CURRENCY_YEAR_END = 'financialInformationCurrencyYearEnd',
}

export interface Modal {
    type: MODAL_TYPES;
    data: any;
}

export type ModalState = Modal[];

export interface ModalProps<D = any> {
    closeModal: (clearModals?: boolean) => void;
    data: D;
}

// this type throws error if ModalList is empty

// @ts-ignore
export type ModalData<T extends ModalTypes> = Parameters<(typeof ModalsList)[T]>['0']['data'];

export type ModalList = {
    [key in MODAL_TYPES]: React.FC<ModalProps>;
};
