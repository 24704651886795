import React, { memo, useCallback, useEffect, useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { IFinancialInformationRow } from 'src/pages/Company/Show/CompanyFinancialInformation/types';
import TargetItemCard from '../TargetItemCard';
import { useTotalSubtotalContext } from '../../../../context';
import ExpressionTokenizer from '../../logic/Tokenizers/ExpressionTokenizer';
import ArrayToExpressionParser from '../../logic/Parsers/ArrayToExpressionParser';
import ExpressionToArrayParser from '../../logic/Parsers/ExpressionToArrayParser';
import { financialValues } from 'src/utils/financial-values';
import { financialInformationLegitFractions } from 'src/pages/Company/Show/CompanyFinancialInformation/constants';
import useFormulaTextBox from './hooks/useFormulaTextBox';
import styles from './FormulaTextBox.module.scss';
import { useFinancial } from 'src/context/companies/Companies';

const FormulaTextBox: React.FC = () => {
    const { currency } = useFinancial((global) => ({
        currency: global.financialSelectedCurrency,
    }));
    const { selectedTotalSubtotal, setFinalResult,
        setRelatedItems, finalResult, formulaString } = useTotalSubtotalContext();
    const {
        setFormulaString,
        formulaJSON,
        setItemMapping,
        targetItems,
        handleFindTokens,
        handleShowChildItems,
        textareaRef,
        handleSelectItem,
        formulaError,
        setFormulaError,
    } = useFormulaTextBox();

    const [textAreaFillLines, setTextAreaFillLines] = useState<number>(0);

    function tryToParseJson() {
        function showRealTimeResultByParsedExpression(parsedExpression: any) {
            try {
                let arrayToExpressionParser = new ArrayToExpressionParser();
                let mathExpression = arrayToExpressionParser.generateValue(parsedExpression);
                // eslint-disable-next-line no-eval
                setFinalResult(eval(mathExpression));
            } catch (error) {}
        }
        try {
            const parser = ExpressionToArrayParser.init();
            let expression = formulaJSON;
            let parsedExpression = parser.parseMathExpression(expression);
            if (parsedExpression) {
                setRelatedItems(expression);
            }
            showRealTimeResultByParsedExpression(parsedExpression);

            if (expression === '') {
                return false;
            }
        } catch (error: any) {
            setFormulaError(error.message);
        }
    }

    const handleSetFormulaString = (e: any) => {
        setFormulaString(e.target.value);
        if (textareaRef.current) {
            const currentHeight = textareaRef.current.style.height;
            textareaRef.current.style.height = 'auto';
            const getHeight = textareaRef.current.scrollHeight;
            textareaRef.current.style.height = currentHeight;
            const lines = String((getHeight - 16) / 20).split('.')[0];
            setTextAreaFillLines(Number(lines));
        }
    };

    const renderResult = useCallback(() => {
        if (!finalResult && !formulaError) {
            return <></>;
        }
        if (finalResult) {
            return (
                <div className={styles.resultWrapper}>
                    <span className={styles.title}>Result</span>
                    <span className={styles.value}>
                        {financialValues(finalResult, {
                            fixed: financialInformationLegitFractions,
                            ...(selectedTotalSubtotal.must_show_currency && { prefix: currency?.short_name }),
                        })}
                    </span>
                </div>
            );
        } else {
            return (
                <div className={styles.resultWrapper}>
                    <span className={styles.title}>Result</span>
                    <span className={styles.inValidFormula}>{formulaError}</span>
                </div>
            );
        }
    }, [finalResult, formulaError, selectedTotalSubtotal])

    useEffect(() => {
        handleShowChildItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formulaString]);

    useEffect(() => {
        if (formulaJSON) tryToParseJson();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formulaJSON]);

    useEffect(() => {
        if (selectedTotalSubtotal.relatedItems) {
            // this is my expression
            const tokenizer = new ExpressionTokenizer();

            const tokens = tokenizer.tokenize(selectedTotalSubtotal.relatedItems);
            let myString = '';
            let tempItemMapping = {};

            tokens.forEach((token: string) => {
                if (token[0] === '{') {
                    myString = myString + `{${JSON.parse(token).name}}`;
                    tempItemMapping = { ...tempItemMapping, [JSON.stringify(JSON.parse(token).name)]: token };
                    setItemMapping(tempItemMapping);
                } else {
                    myString = myString + ' ' + token + ' ';
                }
            });
            handleFindTokens(myString, tempItemMapping);
            setFormulaString(myString);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={styles.textBoxWrapper}>
                <div className={styles.textBoxContainer}>
                    <InputTextarea
                        data-cy={'input_formula'}
                        ref={(e) => (textareaRef.current = e)}
                        className={styles.formulaBox}
                        value={formulaString}
                        onChange={(e) => handleSetFormulaString(e)}
                        style={{ lineHeight: '20px' }}
                    />
                    {targetItems.length > 0 && (
                        <div
                            className={styles.targetItemsWrapper}
                            style={{
                                top: textAreaFillLines * 20 + 20 || 'unset',
                            }}
                        >
                            <span>Select An Item</span>
                            <div>
                                {targetItems.map((child: IFinancialInformationRow) => (
                                    <TargetItemCard key={child.uniqueId} child={child} handleSelectItem={handleSelectItem} />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.boxFooter} data-cy={'formula-result'}>
                    {renderResult()}
                </div>
                <div className={styles.boxFooter}>
                </div>
            </div>
        </>
    );
};

export default memo(FormulaTextBox);
