import { FC } from 'react';
import Button from 'src/components/Kit/Button';
import style from './Landing.module.scss';
import { MUTATION_FINANCIAL_STEP } from '../../../enums';
import { FinancialInformationLandingImage } from 'src/assets/Images/FinancialInformationLandingImage';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { financialActions } from '../../../../../../../context/companies/Companies';
import { checkCompanyType } from 'src/utils/check-company-type';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { useParams } from 'react-router-dom';
import { IParams } from 'src/types/params';

const Landing: FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const { companyType } = useParams<IParams>();
    return (
        <div className={style['financial-information__landing']}>
            {checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_FI_CREATE, companyType as COMPANY_TYPE_ROUTE)) && (
                <>
                    <FinancialInformationLandingImage />
                    <h3>Create Your Company Data</h3>
                    <p>
                        To import company financial information, please click on <b>Create Form</b>
                    </p>
                    <div className={style['financial-information__landing__buttons']}>
                        <Button
                            data-cy={'create-financial-form'}
                            customStyle={{ flex: 1 }}
                            onClick={() => financialActions.setMutationStep(MUTATION_FINANCIAL_STEP.SELECT_TEMPLATE_DATA)}
                            size="l"
                            color="primary"
                        >
                            Create Form
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Landing;
