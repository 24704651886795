
import { InputNumber, InputNumberChangeParams } from 'primereact/inputnumber';
import { memo, ReactNode } from 'react';

interface IProps {
    prefix?: ReactNode;
    id?: number;
    value: number;
    style?: object;
    onChange: (e: InputNumberChangeParams) => void;
    placeholder?: string;
    disabled?: boolean;
    maxFractionDigits?: number;
}
const PrefixInputNumber: React.FC<IProps> = memo( ({ prefix, ...rest }: any) => {
    const inputId = rest.id + '_input';
    const inputClasses = rest.value < 0 ? 'p-inputnumber-negative' : '';
    // Todo: this InputNumber is week , some of the auto suggested
    //  values from the browsers does cause a change in this , change later
    return (
        <div className="p-inputgroup">
            {prefix &&
                <span className="p-inputgroup-addon">
                    {prefix}
                </span>
            }
            <InputNumber {...rest} maxFractionDigits={rest.maxFractionDigits || 0} inputClassName={inputClasses} inputId={inputId} />
        </div>
    );
})

export default PrefixInputNumber